
/*
  The component for the top bar of the page, included in the header.

  props:
    undistracted: Boolean
      Set to true if used in the undistracted layout. If true, some elements might want to be hidden
*/
export default {
  name: 'CaTopBar',
  mixins: [],
  props: {
    undistracted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
